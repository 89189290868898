/* eslint-disable import/no-cycle */
import {FilterEqOperation, IFilterDTO, IFilterModel, IFilterOption, FilterType} from '../types/galleryTypes';
import {FILTER_FIELD} from '../services/filters/CollectionFiltersService';
import {ExternalCollectionsFilter} from '../types/filters';

export class MultiCollectionFilterModel implements IFilterModel {
  public title: string;
  public options: IFilterOption[];
  public activeOptions: string[] = [];
  public selectedOptions: string[] = [];
  public filterId: number;

  constructor(
    public readonly filterType: FilterType.CUSTOM_COLLECTION | FilterType.COLLECTION,
    title: string,
    options: IFilterOption[]
  ) {
    this.title = title;
    this.options = options;
  }

  public updateActiveOptions(optionId: string): void {
    if (!this.activeOptions.includes(optionId)) {
      this.activeOptions.push(optionId);
    } else {
      const index = this.activeOptions.indexOf(optionId);
      this.activeOptions.splice(index, 1);
    }
  }

  public hasActiveOptions(): boolean {
    return !!this.activeOptions.length;
  }

  public isAffectingQuery(): boolean {
    return this.hasActiveOptions();
  }

  public resetActiveOptions(): void {
    this.activeOptions = [];
    this.selectedOptions = [];
  }

  public toDTO(): IFilterDTO {
    return {
      field: FILTER_FIELD,
      op: FilterEqOperation.IN,
      values: this.activeOptions,
    };
  }

  public toExternalDTO(): ExternalCollectionsFilter {
    return this.activeOptions.map((activeOption) => this.options.find((o) => o.key === activeOption).value);
  }
}
