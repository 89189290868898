import {ICategory, ICategoryListItem} from '../types/category';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {
  IFilterConfigDTO,
  IPropsInjectedByViewerScript as IGalleryPropsInjectedByViewerScript,
} from '../types/galleryTypes';
import {RouterPrefix} from '@wix/wixstores-client-core';
import {Experiments} from '../constants';
import {categoryPageCategoryTreeClicked} from '@wix/bi-logger-ec-sf/v2';

export type CategoriesData = {
  currentCategory: ICategory;
  listHeaderCategory: ICategoryListItem;
  categoryList: ICategoryListItem[];
  canHaveBackButton: boolean;
  backButtonUrl: string;
  navigateToCategory(destinationCategoryId: string): void;
};

type ICategoriesService = {
  currentCategory: ICategory;
  allCategories: ICategoryListItem[];
  allProductsCategoryId: string;
  siteStore: SiteStore;
  updateComponent: (props: Partial<IGalleryPropsInjectedByViewerScript>) => void;
};

export class CategoriesService {
  public visibleCategories: ICategoryListItem[];
  private readonly currentCategory: ICategory;
  private readonly allCategories: ICategoryListItem[];
  private readonly allProductsCategoryId: string;
  private readonly siteStore: SiteStore;
  private readonly updateComponent: ICategoriesService['updateComponent'];

  private categoryListConfig: IFilterConfigDTO;
  private currentlySelectedListCategory = {} as ICategoryListItem;
  private idToCategoryMap: {[id: string]: ICategoryListItem} = {};

  constructor({currentCategory, allCategories, allProductsCategoryId, siteStore, updateComponent}: ICategoriesService) {
    this.siteStore = siteStore;
    this.allCategories = allCategories;
    this.allProductsCategoryId = allProductsCategoryId;
    this.updateComponent = updateComponent;

    if (currentCategory) {
      this.currentCategory = this.addUrl(currentCategory);
    }
  }

  private getSubcategories(categoryId: string): ICategoryListItem[] {
    /* istanbul ignore next: hard to test */
    if (!categoryId) {
      return [];
    }

    return this.visibleCategories.filter((category) => category.parentCategoryId === categoryId);
  }

  public getCategorySeoData(
    categoryId: string,
    parentCategoryId: string,
    currentCategoryBreadCrumbs: {name: string; slug: string}[]
  ) {
    const breadcrumbs: {position: number; name: string; item: string}[] = currentCategoryBreadCrumbs?.map(
      (breadcrumb, index) => {
        return {
          position: index + 2,
          name: breadcrumb.name,
          item: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${breadcrumb.slug}`,
        };
      }
    );

    return {
      categoryDirectParentName: this.allCategories.find((category) => parentCategoryId === category.id)?.name,
      categoryParentNames: this.getCategoryParentNames(categoryId),
      breadcrumbs,
    };
  }

  private getCategoryParentNames(categoryId: string): string[] {
    const categoryMap = new Map<string, ICategoryListItem>();
    this.allCategories.forEach((category) => categoryMap.set(category.id, category));

    function findParentNames(currentId: string): string[] {
      const currentCategory = categoryMap.get(currentId);
      if (!currentCategory?.parentCategoryId) {
        return [];
      }

      const parentCategory = categoryMap.get(currentCategory.parentCategoryId);
      /* istanbul ignore next: hard to test - maybe not a valid case but adding guard in case */
      if (!parentCategory) {
        return [];
      }

      return [parentCategory.name, ...findParentNames(parentCategory.id)];
    }

    return findParentNames(categoryId);
  }

  private getCategoryById(categoryId: string): ICategoryListItem {
    if (!categoryId) {
      return null;
    }

    return this.idToCategoryMap[categoryId];
  }

  private getRootLevelCategories(): ICategoryListItem[] {
    return this.visibleCategories.filter((category) => !category.parentCategoryId);
  }

  private getCategoryList(): ICategoryListItem[] {
    const subcategories = this.getSubcategories(this.currentlySelectedListCategory.id);
    if (subcategories.length) {
      return subcategories;
    }

    const parent = this.getCategoryById(this.currentlySelectedListCategory.parentCategoryId);
    if (parent) {
      return this.getSubcategories(parent.id);
    }

    return this.getRootLevelCategories();
  }

  private getListHeaderCategory(): ICategoryListItem {
    const subcategories = this.getSubcategories(this.currentlySelectedListCategory.id);
    if (subcategories.length) {
      return this.getCategoryById(this.currentlySelectedListCategory.id);
    }

    const parent = this.getCategoryById(this.currentlySelectedListCategory.parentCategoryId);
    if (parent) {
      return parent;
    }

    return null;
  }

  private getBackButtonUrl() {
    const parent = this.getCategoryById(this.currentlySelectedListCategory.parentCategoryId);
    return parent?.categoryUrl;
  }

  private readonly navigateToCategory = (destinationCategoryId: string) => {
    const destinationCategory = this.getCategoryById(destinationCategoryId);
    void this.siteStore.webBiLogger.report(
      categoryPageCategoryTreeClicked({
        destinationCategoryId,
        link: destinationCategory?.categoryUrl,
        originCategoryId: this.currentCategory.id,
      })
    );

    this.currentlySelectedListCategory = destinationCategory || ({} as ICategoryListItem);
    if (!destinationCategory || destinationCategoryId === this.currentCategory.id) {
      this.updateComponent({useCategories: this.getProps()});
    }
  };

  private canHaveBackButton() {
    if (this.currentlySelectedListCategory?.parentCategoryId) {
      return true;
    }

    return this.getRootLevelCategories().length > 1;
  }

  public getProps(): CategoriesData {
    return {
      currentCategory: this.currentCategory,
      canHaveBackButton: this.canHaveBackButton(),
      backButtonUrl: this.getBackButtonUrl(),
      listHeaderCategory: this.getListHeaderCategory(),
      categoryList: this.getCategoryList(),
      navigateToCategory: this.navigateToCategory,
    };
  }

  public updateVisibleCategories({
    categoryListConfig,
    shouldUseCategoryListConfig,
  }: {
    categoryListConfig?: IFilterConfigDTO;
    shouldUseCategoryListConfig: boolean;
  }) {
    if (categoryListConfig) {
      this.categoryListConfig = categoryListConfig;
    }

    const usingNewCategoryList = this.siteStore.experiments.enabled(Experiments.NewCategoryList);
    let categories = [...this.allCategories];
    if (shouldUseCategoryListConfig && this.categoryListConfig !== undefined) {
      const sortedIds = [];
      const selectedCategoriesFromConfig = this.categoryListConfig.selected.reduce((obj, item) => {
        obj[item.id] = null;
        sortedIds.push(item.id);
        return obj;
      }, {});

      categories = categories
        .filter((c) => c.visible && c.id in selectedCategoriesFromConfig)
        .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
    } else {
      categories = categories.filter((c) => c.visible).sort((a, b) => a.name.localeCompare(b.name));
      const defaultCategoryIndex = categories.findIndex((c) => c.id === this.allProductsCategoryId);
      if (defaultCategoryIndex !== -1) {
        categories.unshift(categories.splice(defaultCategoryIndex, 1)[0]);
      }

      if (usingNewCategoryList) {
        categories.sort((a, b) => a.parentCategoryIndex - b.parentCategoryIndex);
      }
    }

    if (!usingNewCategoryList) {
      this.visibleCategories = categories.map((c) => {
        return {
          ...c,
          categoryUrl: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${c.slug}`,
        };
      });
      return;
    }

    this.visibleCategories = categories.map(this.addUrl);
    this.idToCategoryMap = Object.fromEntries(this.visibleCategories.map((c) => [c.id, c]));
    if (this.currentCategory) {
      this.currentlySelectedListCategory = this.currentCategory;
    }
  }

  private readonly addUrl = (category) => {
    return {...category, categoryUrl: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${category.slug}`};
  };
}
